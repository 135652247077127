import React, { useRef, useState, useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CBtnList,
  CSectTitle03,
  CCard,
  CFaqList,
  CSectMedia,
  LContact03,
} from '../../../components/_index';
import infoChoice from '../../../utils/info-choice';
import infoGet from '../../../utils/info-get';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        type="ja"
        data={{
          title: {
            main: 'お子様連れのお客様へ',
            sub: <>FAMILY</>,
          },
          imgList: [
            {
              img: {
                src: '/assets/images/stay/child/kv.png',
              },
              imgSp: {
                src: '/assets/images/stay/child/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>
      <section className="l_sect">
        <LWrap>
          <p className="c_headingLv2 u_tac_sp">ご予約について</p>
          <p className="c_sectLead">
            小学生以下のお子様は、ベッド一台につき1名様の添い寝を無料にて承ります（※）。
            <br />
            ご予約の際、添い寝のお子様は人数に含まず、別途備考欄に添い寝のお子様がいらっしゃる旨をご記入ください。
            <br />
            小学生以下のお子様お一人でベッドをご利用になる場合は、大人料金を頂戴いたします。
            <br />
            ※エキストラベッドを除く
          </p>
          <CSectTitle03 title="添い寝のお子様のお食事について" />
          <CCard
            col={2}
            data={[
              {
                img: {
                  src: '/assets/images/stay/child/img_meals.png',
                  alt: '',
                },
                title: <>スタンダードフロアにご宿泊の方</>,
                content: (
                  <>
                    シェフズダイニング
                    シンフォニーの朝⾷ブッフェは、4~12歳のお⼦様は2,200円にてお召しあがりいただけます。3歳以下のお⼦様は無料にて承っております。
                    <ul className="c_noteList u_mt15">
                      <li>料金には消費税・サービス料が含まれます。</li>
                    </ul>
                  </>
                ),
              },
              {
                img: {
                  src: '/assets/images/stay/child/img_meals02.png',
                  alt: '',
                },
                title: <>スイート、エグゼクティブフロアにご宿泊の方</>,
                content: (
                  <>
                    エグゼクティブラウンジにて、ご朝食やティータイムを無料でご利用いただけます。カクテルタイムは小学生以下のお子様のご入場をご遠慮いただいております。
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="u_mbLarge">
        <LWrap>
          <CSectTitle03 title="お子様用アメニティ・貸出備品" />
          <p className="c_sectLead">
            お子様用のアメニティセットや下記のような貸出備品のご用意がございます。
          </p>
          <CCard
            col={2}
            data={[
              {
                img: {
                  src: '/assets/images/stay/child/img_amenity.png',
                  alt: '',
                },
                title: <>キッズアメニティセット</>,
                content: (
                  <>
                    お子様用のアメニティセットをご用意しております。
                    <ul className="c_circleList">
                      <li>セット内容：歯ブラシ、スリッパ、タオル</li>
                    </ul>
                  </>
                ),
              },
              {
                img: {
                  src: '/assets/images/stay/child/img_amenity02.png',
                  alt: '',
                },
                title: <>お子様用パジャマ</>,
                content: (
                  <>
                    貸出備品のご用意がございます。
                    <ul className="c_circleList">
                      <li>お子様用パジャマ（S、Mサイズ）</li>
                    </ul>
                    <ul className="c_noteList03">
                      <li>Ｓサイズ…身長90cm~100cm相当</li>
                      <li>Mサイズ…身長110cm~130cm相当</li>
                    </ul>
                  </>
                ),
              },
              {
                img: {
                  src: '/assets/images/stay/child/img_amenity03.png',
                  alt: '',
                },
                title: <>ベビーベッド(1歳未満のお子様が対象)</>,
              },
              {
                img: {
                  src: '/assets/images/stay/child/img_amenity04.png',
                  alt: '',
                },
                title: <>ベッドガード</>,
                content: (
                  <>
                    <p className="u_fwb">その他貸出備品</p>
                    <ul className="c_circleList04">
                      <li>おむつ用のごみ箱追加</li>
                      <li>防水シーツ</li>
                      <li>DVDプレーヤー</li>
                    </ul>
                    <ul className="c_noteList">
                      <li>ディスクはご持参ください。</li>
                    </ul>
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="u_mbLarge">
        <LWrap>
          <CSectTitle03 title="よくあるご質問" />
          <CFaqList
            data={[
              {
                q: '子供が一緒の場合、追加料金はかかりますか?',
                a: (
                  <>
                    小学生以下のお子様は、添い寝の場合に限り無料です。詳細は
                    <Link to="#">こちら</Link>をご確認ください。
                    <br />
                    エグゼクティブラウンジのご利用（スイート、エグゼクティブフロアご宿泊者のみ）は、17:00以降小学生以下のお子様のご入場をご遠慮いただいております。
                  </>
                ),
              },
              {
                q: 'ミルクの温めや哺乳瓶の消毒はできますか？',
                a: (
                  <>
                    <ul className="c_circleList u_mb0">
                      <li>
                        ミルク用のお湯：お部屋に備え付けの電気ケトルをご利用いただけます。
                      </li>
                      <li>
                        哺乳瓶の消毒や、離乳食などの温め：1Fに電子レンジコーナーをご用意しております。もしくはルームサービスでも、お預かりさせていただきご対応を承ります。
                      </li>
                    </ul>
                    <p>
                      哺乳瓶の消毒には、電子レンジ対応の消毒キットをご持参ください。
                    </p>
                  </>
                ),
              },
              {
                q: 'おむつ替えの出来るところはありますか?',
                a: (
                  <>
                    下記をご利用いただけます。
                    <br />
                    ベビーベッド：1F
                    フロントカウンター横（授乳室併設、ご利用の際はスタッフにお申し付けください）
                    <br />
                    折りたたみシート：１F バリアフリートイレ内
                    <br />
                    おむつ替えベッド：B1F、2F、4F 女性用化粧室内
                  </>
                ),
              },
            ]}
          />
          <CBtnList
            exClass="u_mt30"
            data={[
              {
                label: 'よくあるご質問',
                link: {
                  href: '/faq/child/',
                },
                color: 'bgWhite03',
              },
            ]}
          />
        </LWrap>
      </section>
      {/* <section className="u_mb100">
        <LWrap>
          <CSectMedia
            data={[
              {
                img: {
                  src: '/assets/images/stay/child/img_oiwai.png',
                  alt: '',
                },
                imgSp: {
                  src: '/assets/images/stay/child/img_oiwai__sp.png',
                  alt: '',
                },
                link: {
                  href: '/oiwai/',
                },
                title: <>お祝い</>,
                text: (
                  <>
                    安産祈願、ご出産、お子様の成長、ご家族でのお祝いに。ご家族でのシーンに合わせたプランを各種ご用意しております。
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section> */}
      <CBreadCrumb
        data={{
          parent: [
            {
              label: '宿泊',
              path: '/stay/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LContact03
        title="ご予約・お問合せ"
        btn={{
          btn: {
            label: 'お問合せフォーム',
            link: {
              href: 'https://contact.royalparkhotels.co.jp/rph/contact?_ifbs-rph_contact_form=s1_Step1',
              blank: true,
            },
          },
        }}
        tel={{
          number: '03-5641-2958',
          note: '宿泊営業課（受付時間 平日 8:00～20:00／土・日・祝日 9:00～18:00）',
        }}
      />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
